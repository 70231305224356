import { Component, OnInit } from '@angular/core';
import { Blog } from '../model/blog';
import { BlogService } from '../services/blog.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  blog?: Blog[];

  constructor(
    private blogService: BlogService
  ) {}

  ngOnInit(): void {
    this.blogService.getBlogs().subscribe(blog => {
      this.blog = blog;
    });
  }

}
