<section class="cover-image-container">
    <img src="./../../assets/team-vac.jpg" class="image" />
    <div class="overlay">
        <h1>Légy Te is a Váci Vízilabda SE vízilabdázója</h1>
        <button [routerLink]="['/csatlakozz']" class="button is-light">Részletek</button>
    </div>
</section>
<section class="about-container">
    <div class="container">
        <div class="rolunk">
            <p class="title">Bemutatkozás</p>
            <div class="columns">
                <div class="column">
                    <p>Egyesületünk 1996-os alakulása Kolossa István (1935-2013) mesteredző nevéhez fűződik. A kezdetben
                        úszóként
                        versenyző Kolossa István vízilabda-játékosként és edzőként is sokat tett a sportért,
                        utánpótlás-nevelőként
                        fiatalok generációit tanította vízilabdázni. Vác városában szinte a semmiből teremtette meg és
                        tette ismert
                        sportággá mintegy három évtizeddel ezelőtt a pólót, de számos más helyen, egyesületben
                        is tevékenykedett. Igazi megszállott volt, a víz szerelmese. Tevékenységéért számos alkalommal elismerésben részesült,
                        így például 2010-ben Vác Város Sportjáért kitüntetést kapott.</p>
                    <p>Büszkék vagyunk rá, hogy hozzá méltóan tevékenykedünk mind a mai napig. Évről évre egyre
                        kimagaslóbb
                        eredményeket elérő utánpótlás csapataink kitartóan dolgoznak a vízilabda sportban való sikeres
                        szereplésükért.</p>
                    <p>Szeretnénk Vácon egy biztos alapokra épülő, sikeres utánpótlás bázist létrehozni és az itt
                        sportoló
                        gyerekek
                        közül reményeink szerint a jövő olimpikonjai számát is gyarapítani tudjuk. Minden évben újult
                        erővel
                        vágunk
                        neki munkásságunknak, hogy álmainkat megvalósíthassuk</p>
                    <p>Szeretnénk Kolossa István bácsi álmait tovább fokozni és sikeresen megvalósítani! Mert nem szabad
                        elfelejteni
                        - amit egy nagyszerű ember mondott -, egyesületünk jelmondatát:</p>
                    <h2>"A CSAPATSZELLEM TESZ IGAZÁN NAGGYÁ!"</h2>
                </div>
                <div class="column image-kolossa">

                </div>
            </div>
            <div class="content-center">
                <button [routerLink]="['/rolunk']" class="button is-light">Klubunkról bővebben</button>
            </div>
        </div>
    </div>
</section>
<section class="container">
    <div class="block blog">
        <h1 class="title">Friss hírek</h1>
        <div class="columns">
            <div class="column" *ngFor="let item of lastNews">
                <div class="card">
                    <div class="card-image">
                        <figure class="image-container">
                            <img [src]="item.imgSrc" alt="Placeholder image" />
                        </figure>
                    </div>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-5">{{ item.title }}</p>
                            </div>
                        </div>
                        <div class="content blog-action">
                            <button [routerLink]="['/blog', item.id]" class="button is-light">Részletek</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-section">
    <div class="container">
        <h1 class="title has-text-white">Miért csatlakozz hozzánk?</h1>
        <div class="columns">
            <div class="column about-item">
                <div class="about-img">
                    <img src="./../../assets/ball.png" width="100px" height="100px" />
                </div>
                <h1 class="title is-3 has-text-white">Vízilabda</h1>
                <p class="has-text-justified">Magyarország legsikeresebb csapatsportja!</p>
            </div>
            <div class="column about-item">
                <div class="about-img">
                    <img src="./../../assets/team.png" width="100px" height="100px" />
                </div>
                <h1 class="title is-3 has-text-white">Családias környezet</h1>
                <p class="has-text-justified">Nevelőegyesület lévén nálunk a fókusz az utánpótlás korosztályokon van.
                    Csapataink előrehaladása mellett prioritás számunkra az egyéni fejlődés segítése támogató
                    környezetben.</p>
            </div>
            <div class="column about-item">
                <div class="about-img">
                    <img src="./../../assets/professional.png" width="100px" height="100px" />
                </div>
                <h1 class="title is-3 has-text-white">Professzionális szakértelem</h1>
                <p class="has-text-justified">Egyesületünk vezetői és edzői olyan magas szintű szaktudással
                    rendelkeznek, mely támogatja a gyerekek megfelelő fejlődését és lehetőséget biztosít magas szintre
                    eljutni ebben a sportágban.</p>
            </div>
        </div>
    </div>
</section>
<section class="container">
    <div class="instagram-container">
        <div class="instagram-feed">
            <h1 class="title">Kövess minket a közösségi oldalakon!</h1>
            <hr />
            <div class="follow-link columns">
                <div class="icons column">
                    <a target="_blank" href="https://www.facebook.com/vacivizilabda"><img width="20px"
                            src="./../../assets/facebook-icon.png"> facebook.com/vacivizilabda</a>
                </div>
                <div class="icons column">
                    <a target="_blank" href="https://www.instagram.com/vacivizilabda.se/"><img width="20px"
                            src="./../../assets/instagram-icon.png"> @vacivizilabda.se</a>
                </div>
            </div>
        </div>
    </div>
</section>