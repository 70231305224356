import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Coach } from '../model/coach';

@Injectable({
  providedIn: 'root'
})
export class CoachService {
  private coachUrl = 'assets/data/coaches.json';

  constructor(private http: HttpClient) {}

  // Az összes blog bejegyzés lekérdezése
  getCoaches(): Observable<Coach[]> {
    return this.http.get<Coach[]>(this.coachUrl);
  }

  getCoachById(id: number): Observable<Coach | undefined> {
    return new Observable<Coach | undefined>(observer => {
      this.getCoaches().subscribe(coaches => {
        const coach = coaches.find(b => b.id === id);
        observer.next(coach);
        observer.complete();
      });
    });
  }
}
