<div class="title-bg">
  <div class="background-blur"></div>
  <div class="title-overlay">
    <h1 class="title is-1 has-text-white">Klub</h1>
  </div>
</div>

<section class="container">
  <div #parentDiv class="white-bg">
    <div class="rolunk">
      <p class="title">Váci Vízilabda SE</p>
      <div class="columns">
        <div class="column">
          <p>Egyesületünk 1996-os alakulása Kolossa István (1935-2013) mesteredző nevéhez fűződik. A kezdetben
            úszóként
            versenyző Kolossa István vízilabda-játékosként és edzőként is sokat tett a sportért,
            utánpótlás-nevelőként
            fiatalok generációit tanította vízilabdázni. Vác városában szinte a semmiből teremtette meg és
            tette ismert
            sportággá mintegy három évtizeddel ezelőtt a pólót, de számos más helyen, egyesületben
            is tevékenykedett. Igazi megszállott volt, a víz szerelmese. Tevékenységéért számos alkalommal elismerésben
            részesült,
            így például 2010-ben Vác Város Sportjáért kitüntetést kapott.</p>
          <p>Büszkék vagyunk rá, hogy hozzá méltóan tevékenykedünk mind a mai napig. Évről évre egyre
            kimagaslóbb
            eredményeket elérő utánpótlás csapataink kitartóan dolgoznak a vízilabda sportban való sikeres
            szereplésükért.</p>
          <p>Szeretnénk Vácon egy biztos alapokra épülő, sikeres utánpótlás bázist létrehozni és az itt
            sportoló
            gyerekek
            közül reményeink szerint a jövő olimpikonjai számát is gyarapítani tudjuk. Minden évben újult
            erővel
            vágunk
            neki munkásságunknak, hogy álmainkat megvalósíthassuk</p>
          <p>Szeretnénk Kolossa István bácsi álmait tovább fokozni és sikeresen megvalósítani! Mert nem szabad
            elfelejteni
            - amit egy nagyszerű ember mondott -, egyesületünk jelmondatát:</p>
          <h2>"A CSAPATSZELLEM TESZ IGAZÁN NAGGYÁ!"</h2>
        </div>
        <div class="column image-kolossa">

        </div>
      </div>
    </div>
    <div class="waterpolo-training">
      <img src="./../../assets/foglalkozasok-scaled.jpg" width="100%" />
    </div>
    <div class="our-goals">
      <h1 class="title">Céljaink</h1>
      <div class="columns">
        <div class="column goals-img">
          <img src="./../../assets/waterpolo.png" width="75%" />
        </div>
        <div class="column rolunk">
          <p>Célunk a több évtizede felépített utánpótlás bázis tovább bővítése és a vízilabdázás életérzéssé formálása.
          </p>

          <p>Legkisebbektől a felnőtt korosztályig biztosítjuk a vízilabdázási lehetőséget lányok és fiúk számára
            egyaránt immár 28 éve a városban. Területi és országos bajnokságokban indulunk a kezdetektől.</p>

          <p>Nálunk a cél maga az út.</p>
          <p>Az út ,melyen végigkísérjük játékosainkat az alapoktól egy olyan egyéni maximum felé, amely által a sport
            és azon belül a vízilabda meghatározó eleme lesz a mindennapoknak.</p>
        </div>
      </div>
    </div>
    <div class="coaches">
      <h1 class="title">Edzőink</h1>

      <pseudo-3d-carousel *ngIf="coaches" class="custom" #carousel [images]="coaches" [radius]="parentWith/3" [top]="10" [timer]="200"
        [minScale]=".25"></pseudo-3d-carousel>

      <div class="rolunk">
        <p>Edzőink más-más módszerekkel dolgoznak, különböző habitussal igyekeznek tudásukat átadni, de közös bennük,
          hogy hisznek sportolóikban és bíznak benne, hogy hatásukra a mozgás életformává válik.
          Köszönhetően a magas szakmai képzettségüknek és tapasztalatuknak, a nálunk vízilabdázni tanulók előtt ott a
          lehetőség, hogy akár a legmagasabb szintre jussanak el a sportágban, de legalább annyira fontos számunkra,
          hogy mindenkivel megismertessük a sportág szépségét és biztosítsuk a mozgás és játék lehetőségét akkor is,
          ha nem a válogatottban való szereplés a cél.</p>
      </div>
      <div class="content-center">
        <button [routerLink]="['/edzoink']" class="button is-light">Tovább az edzőinkre</button>
      </div>
    </div>
  </div>
</section>