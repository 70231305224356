<div class="title-bg">
    <div class="background-blur"></div>
    <div class="title-overlay">
        <h1 class="title is-1 has-text-white">Edzőink</h1>
    </div>
</div>
<section class="container">
    <div class="columns is-multiline coach-container">
        <div class="column is-one-third-tablet" *ngFor="let item of coaches">
            <div class="card">
                <div class="card-image">
                    <figure class="image">
                        <img [src]="item.imgSrc" alt="Placeholder image" />
                    </figure>
                </div>
                <div class="card-content">
                    <div class="media">
                        <div class="media-content">
                            <p class="title is-4">{{ item.name }}</p>
                            <p class="subtitle is-6">
                                {{ item.position }}
                                <br />
                                Telefonszám: {{ item.phone }}
                        </div>
                    </div>
                    <div class="content is-float-text">
                        <p [innerHTML]="item.desc"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>