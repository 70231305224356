import { Component, OnInit } from '@angular/core';
import { CoachService } from '../services/coach.service';
import { Coach } from '../model/coach';

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.scss']
})
export class CoachComponent implements OnInit {
    coaches: Coach[];

    constructor(private coachService: CoachService) { }
  
  
    ngOnInit(): void {
      this.coachService.getCoaches().subscribe(res => this.coaches = res);
    }

}
