import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageFile } from '../model/image-file';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private imagesUrl = 'assets/data/gallery.json'; // Az útvonal a JSON fájlhoz
  private galleryUrl = 'assets/data/images.json'; // Az útvonal a JSON fájlhoz

  constructor(private http: HttpClient) {}

  // Az összes image lekérdezése
  getImages(): Observable<ImageFile[]> {
    return this.http.get<ImageFile[]>(this.imagesUrl);
  }

  // Egy image bejegyzés lekérdezése ID alapján
  getImageById(id: number): Observable<ImageFile | undefined> {
    return new Observable<ImageFile | undefined>(observer => {
      this.getImages().subscribe(images => {
        const image = images.find(b => b.id === id);
        observer.next(image);
        observer.complete();
      });
    });
  }

  getLatestimages(): Observable<ImageFile[]> {
    return this.getImages().pipe(
      map(images => {
        return images
          .sort((a, b) => b.id - a.id) // Csökkenő sorrend ID alapján
          .slice(0, 3); // Az első három bejegyzés
      })
    );
  }

  getGaleries(): Observable<ImageFile[]> {
    return this.http.get<ImageFile[]>(this.galleryUrl);
  }
  
}
