// src/app/services/blog.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Blog } from '../model/blog';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private blogsUrl = 'assets/data/blogs.json'; // Az útvonal a JSON fájlhoz

  constructor(private http: HttpClient) {}

  // Az összes blog bejegyzés lekérdezése
  getBlogs(): Observable<Blog[]> {
    return this.http.get<Blog[]>(this.blogsUrl);
  }

  // Egy blog bejegyzés lekérdezése ID alapján
  getBlogById(id: number): Observable<Blog | undefined> {
    return new Observable<Blog | undefined>(observer => {
      this.getBlogs().subscribe(blogs => {
        const blog = blogs.find(b => b.id === id);
        observer.next(blog);
        observer.complete();
      });
    });
  }

  getLatestBlogs(): Observable<Blog[]> {
    return this.getBlogs().pipe(
      map(blogs => {
        return blogs
          .sort((a, b) => b.id - a.id) // Csökkenő sorrend ID alapján
          .slice(0, 3); // Az első három bejegyzés
      })
    );
  }
}
