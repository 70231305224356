import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DocumentsComponent } from './documents/documents.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { NewsComponent } from './news/news.component';
import { CoachComponent } from './coach/coach.component';
import { JoinComponent } from './join/join.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AboutComponent } from './about/about.component';

export const routes: Routes = [
  { path: '', component: HomeComponent }, // Főoldalra mutató útvonal
  { path: 'dokumentumok', component: DocumentsComponent }, // Dokumentumok oldalra mutató útvonal
  { path: 'blog/:id', component: BlogComponent },
  { path: 'kapcsolat', component: ContactComponent },
  { path: 'hirek', component: NewsComponent },
  { path: 'edzoink', component: CoachComponent },
  { path: 'csatlakozz', component: JoinComponent },
  { path: 'galeria', component: GalleryComponent },
  { path: 'rolunk', component: AboutComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' } // Alapértelmezett útvonal
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
