import { Component, OnInit } from '@angular/core';
import { Blog } from '../model/blog';
import { BlogService } from '../services/blog.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public lastNews: Blog[];

  constructor(private blogService: BlogService) { }

  ngOnInit(): void {
    this.blogService.getLatestBlogs().subscribe(response => {
      this.lastNews = response;
    });
  }

}
