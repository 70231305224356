<div class="title-bg">
  <div class="background-blur"></div>
  <div class="title-overlay">
      <h1 class="title is-1 has-text-white">Galéria</h1>
  </div>
</div>

<section class="container">
    <div class="gallery">
        <div *ngFor="let image of filteredList" class="thumbnail">
          <img [src]="image.imgSrc" [alt]="image.name" (click)="selectImage(image)">
        </div>
      </div>
      
      <!-- Image Overlay -->
      <div class="overlay" *ngIf="showOverlay" (click)="closeOverlay()">
        <div class="overlay-content" (click)="$event.stopPropagation()">
          <img [src]="selectedImage?.imgSrc" [alt]="selectedImage?.name" class="large-image">
          <button class="nav left" (click)="navigate('prev')"><i class="ph ph-arrow-circle-left"></i></button>
          <button class="nav right" (click)="navigate('next')"><i class="ph ph-arrow-circle-right"></i></button>
        </div>
      </div>

      <div class="show-more">
      <button class="button is-light" *ngIf="images.length > filteredList.length" (click)="showMore()">Mutass többet!</button>
      </div>
</section>