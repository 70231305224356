<div class="title-bg">
    <div class="background-blur"></div>
    <div class="title-overlay">
        <h1 class="title is-1 has-text-white">Aktuális</h1>
    </div>
</div>
<section class="container">
    <div class="fixed-grid has-1-cols-mobile has-3-cols">
        <div class="grid">
            <div class="cell" *ngFor="let item of blog">
                <div class="card">
                    <div class="card-image">
                        <figure class="image-container">
                            <img [src]="item.imgSrc" alt="Placeholder image" />
                        </figure>
                    </div>
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-5">{{ item.title }}</p>
                            </div>
                        </div>
                        <div class="content blog-action">
                            <button [routerLink]="['/blog', item.id]" class="button is-light">Részletek</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>