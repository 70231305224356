import { Component, OnInit } from '@angular/core';
import { ImageFile } from '../model/image-file';
import { ImageService } from '../services/image.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    images: ImageFile[];
    morePic = 12;
    maxId = 0;

    constructor(private imageService: ImageService) { }  
  
    ngOnInit(): void {
      this.imageService.getImages().subscribe(res => {
        this.images = res;
        this.images.sort((img1, img2) => img2.id - img1.id);
        console.log(this.images);
        console.log(this.filteredList);
        this.images.map(item => {
          if (item.id > this.maxId) this.maxId = item.id;
        });
      });
    }

    selectedImage: ImageFile | null = null;
    showOverlay: boolean = false;
  
    selectImage(image: ImageFile): void {
      this.selectedImage = image;
      this.showOverlay = true;
    }
  
    closeOverlay(): void {
      this.showOverlay = false;
      this.selectedImage = null;
    }
  
    navigate(direction: string): void {
      if (!this.selectedImage) return;
  
      const currentIndex = this.images.findIndex(img => img.id === this.selectedImage!.id);
      if (direction === 'prev') {
        const prevIndex = (currentIndex === 0) ? this.images.length - 1 : currentIndex - 1;
        this.selectedImage = this.images[prevIndex];
      } else if (direction === 'next') {
        const nextIndex = (currentIndex === this.images.length - 1) ? 0 : currentIndex + 1;
        this.selectedImage = this.images[nextIndex];
      }
    }

    get filteredList(): ImageFile[] {
      return this.images.sort((img1, img2) => img2.id - img1.id).filter(item => item.id > this.maxId - this.morePic);
    }

    showMore() {
      this.morePic += 12;
    }
}
