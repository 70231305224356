<div class="carousel" [style.width.px]="cellWidth" [style.margin-top.px]="marginTop">
  <div #element *ngFor="let i of [0, 2, 4, 12, 14]; let index = index" (click)="indexToFront(index)">
    <div class="image-card">
      <img (load)="index == 0 && getDimensions(element)" [src]="images[index].imgSrc" />
      <div class="media-content">
        <p class="coach-name">{{ images[index].name }}</p>
        <p class="coach-pos">
          {{ images[index].position }}
      </div>
    </div>
  </div>
</div>