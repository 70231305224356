<section>
    <div class="title-bg">
        <div class="background-blur"></div>
        <div class="title-overlay">
            <h1 class="title is-1 has-text-white">Kapcsolat</h1>
        </div>
    </div>
    <section class="container">
        <div class="contact-container">
            <div class="columns">
                <div class="column">
                    <img height="97px" src="/assets/logo.png" />
                    <p class=""><span class="has-text-weight-bold">Váci Vízilabda SE</span></p>
                    <p>2600 Vác, Ady Endre sétány 16.</p>
                    <p><span class="has-text-weight-bold">E-mail: </span>vacivizilabdase@gmail.com</p>
                    <p><span class="has-text-weight-bold">Telefonszám: </span>+36706370380</p>
                    <p><span class="has-text-weight-bold">Adószám:</span> 18671685-2-13</p>
                    <p><span class="has-text-weight-bold">Bankszámla szám:</span> 11742094-20150279</p>
                </div>
                <div class="column">
                    <div class="is-mobile">
                        <div class="mapouter">
                            <div class="gmap_canvas"><iframe
                                    src="https://maps.google.com/maps?q=V%C3%A1c%20ady%20endre%20s%C3%A9t%C3%A1ny%2016&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                                    frameborder="0" scrolling="no" style="width: 300px; height: 300px;"></iframe>
                                <style>
                                    .mapouter {
                                        display: table;
                                    }

                                    .gmap_canvas {
                                        overflow: hidden;
                                        position: relative;
                                        background: #fff;
                                    }
                                </style><a href="https://www.taxuni.com/form-720-instructions/">Form 720 Instructions</a>
                                <style>
                                    .gmap_canvas iframe {
                                        position: relative !important;
                                        z-index: 2 !important;
                                    }

                                    .gmap_canvas a {
                                        color: #fff !important;
                                        position: absolute !important;
                                        top: 0 !important;
                                        left: 0 !important;
                                        z-index: 0 !important;
                                    }
                                </style>
                            </div>
                        </div>
                    </div>
                    <div class="is-desktop">
                        <div class="mapouter">
                            <div class="gmap_canvas"><iframe
                                    src="https://maps.google.com/maps?q=V%C3%A1c%20ady%20endre%20s%C3%A9t%C3%A1ny%2016&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                                    frameborder="0" scrolling="no" style="width: 500px; height: 500px;"></iframe>
                                <style>
                                    .mapouter {
                                        display: table;
                                    }

                                    .gmap_canvas {
                                        overflow: hidden;
                                        position: relative;
                                        background: #fff;
                                    }
                                </style><a href="https://www.taxuni.com/form-720-instructions/">Form 720 Instructions</a>
                                <style>
                                    .gmap_canvas iframe {
                                        position: relative !important;
                                        z-index: 2 !important;
                                    }

                                    .gmap_canvas a {
                                        color: #fff !important;
                                        position: absolute !important;
                                        top: 0 !important;
                                        left: 0 !important;
                                        z-index: 0 !important;
                                    }
                                </style>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-center">
                    <h1 class="title is-4">Kövess minket facebookon és instagramon!</h1>
                </div>
            </div>
        </div>
    </section>
</section>