import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Coach } from '../model/coach';
import { CoachService } from '../services/coach.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit, AfterViewInit {
  translateX = 0;
  private imageWidth = 220; // Kép szélessége + margók
  private intervalId: any;

  @ViewChild('parentDiv') parentDiv!: ElementRef;
  parentWith = 500;

  coaches: Coach[];

  constructor(private coachService: CoachService) { }


  ngOnInit(): void {
    this.coachService.getCoaches().subscribe(res => {
      this.coaches = res;
      this.startCarousel();
    });
  }

  ngAfterViewInit(): void {
    this.parentWith = this.parentDiv.nativeElement.offsetWidth;
  }

  startCarousel(): void {
    this.intervalId = setInterval(() => {
      this.translateX -= this.imageWidth;

      // Ha az első kép teljesen kikerült, újrarendereljük a képeket
      if (Math.abs(this.translateX) >= this.imageWidth * this.coaches.length) {
        this.translateX = 0;
        this.rearrangeImages();
      }
    }, 2000); // Módosítható az időzítés
  }

  rearrangeImages(): void {
    // Az első képet áthelyezzük a lista végére
    const firstImage = this.coaches.shift();
    if (firstImage) {
      this.coaches.push(firstImage);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId); // Tisztítás az erőforrások szivárgásának elkerülése érdekében
  }
}
