<section>
    <div class="title-bg">
        <div class="background-blur"></div>
        <div class="title-overlay">
            <h1 class="title is-1 has-text-white">Dokumentumok</h1>
        </div>
    </div>
    <section class="container">
        <div>
            <h2 class="subtitle">Hasznos dokumentumok</h2>
            <div class="columns is-3">
                <div class="column is-one-third" *ngFor="let item of files">
                    <div class="grid-item">
                        <a [href]="item.link" target="_blank" class="document-link">
                            <img src="../../assets/file-pdf.svg" width="45px" height="45px">
                            <h3>{{ item.title }}</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div>
            <h2 class="subtitle">Aktuális dokumentumok</h2>
            <div class="columns is-3">
                <div class="column is-one-third">
                    <div class="grid-item">
                        <a href="/assets/sfp_jh_000_12166_2023_mvlsz_2024-02-26_1708938512_1709656687.pdf"
                            target="_blank" class="document-link">
                            <img src="../../assets/file-pdf.svg" width="45px" height="45px">
                            <h3>TAO jóváhagyó határozat</h3>
                        </a>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="grid-item">
                        <a href="/assets/sfp_publikus_12166_20240306_1709708029.pdf" target="_blank" class="document-link">
                            <img src="../../assets/file-pdf.svg" width="45px" height="45px">
                            <h3>SFP 1</h3>
                        </a>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="grid-item">
                        <a href="/assets/sfp_publikus_14023_20240307_1709827048.pdf" target="_blank" class="document-link">
                            <img src="../../assets/file-pdf.svg" width="45px" height="45px">
                            <h3>SFP 2</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <h2 class="subtitle">Korábbi dokumentumok</h2>
        <div class="columns is-multiline is-3">
            <div class="column is-one-third" *ngFor="let item of previousFiles">
                <div class="grid-item">
                    <a [href]="item.link" target="_blank" class="document-link">
                        <img src="../../assets/file-pdf.svg" width="45px" height="45px">
                        <h3>{{ item.title }}</h3>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <!--
    <section>
        <a href="/assets/documents/sfp_publikus_14023_20240307_1709827048.pdf" target="_blank" rel="noopener">
            <h4>SFP 2024/2025</h4>
        </a>
        <a href="/assets/documents/sfp_jh_000_14023_2024_mvlsz_2024-03-07_1709816294_1709826564-1.pdf" target="_blank"
            rel="noopener">
            <h4>TAO jóváhagyó határozat 2024/2025</h4>
        </a>
        <a href="/assets/documents/Alapszabaly-2020.03.13.pdf" target="_blank" rel="noopener">
            <h4>Alapszabály</h4>
        </a>
        <a href="/assets/documents/VAC_2023MUK_SFP_jh.hatarozat.pdf" target="_blank" rel="noopener">
            <h3>TAO jóváhagyó határozat 2023/2024</h3>
            <p></p>
        </a>
        <a href="/assets/documents/VAC_2023MUK_SFP_jh.kerelem_publ.pdf" target="_blank" rel="noopener">
            <h3>SFP 2023/2024</h3>
            <p></p>
        </a>
        <a href="/assets/documents/sfp_jh_000_10023_2022_mvlsz_2022-03-11_1647005078_1647007450.pdf" target="_blank"
            rel="noopener">
            <h3>TAO jóváhagyó határozat 2022/2023</h3>
            <p></p>
        </a>
        <a href="/assets/documents/sfp_publikus_10023_20220317_1647500720.pdf" target="_blank" rel="noopener">
            <h3>SFP 2022/2023</h3>
            <p></p>
        </a>
        <a href="/assets/documents/sfp_jh_000_08023_2021_mvlsz_2021-04-07_1617779172_1617957388.pdf" target="_blank"
            rel="noopener">
            <h3>TAO jóváhagyó határozat 2021/2022</h3>
            <p></p>
        </a>
        <a href="/assets/documents/sfp_publikus_08023_20210410_1618030073.pdf" target="_blank" rel="noopener">
            <h3>SFP 2021/2022</h3>
            <p></p>
        </a>
        <a href="/assets/documents/SFP_jovahagyo_hatarozat2020_2021.pdf" target="_blank" rel="noopener">
            <h3>TAO jóváhagyó határozat 2020/2021</h3>
            <p></p>
        </a>
        <a href="/assets/documents/SFP_2020_2021.pdf" target="_blank" rel="noopener">
            <h3>SFP 2020/2021</h3>
            <p></p>
        </a>
        <a href="/assets/documents/sportorvosi-nyilatkozatok.pdf" target="_blank" rel="noopener">
            <h3>Sportorvosi nyilatkozatok</h3>
            <p></p>
        </a>
        <h1>Beruházások</h1>
        <a href="/assets/documents/sfp_publikus_14023_20240307_1709827048.pdf" target="_blank" rel="noopener">
            <h3>SFP 2024/2025</h3>
            <p></p>
        </a>
        <a href="/assets/documents/sfp_jh_000_14023_2024_mvlsz_2024-03-07_1709816294_1709826564-1.pdf" target="_blank"
            rel="noopener">
            <h3>TAO jóváhagyó határozat 2024/2025</h3>
            <p></p>
        </a>
    </section>
-->
</section>